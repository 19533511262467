import Header from "components/Headers/Header";
import { IsError, IsLoading } from "features/components/LoadingErrorComponents";
import { Pagina } from "components/Pagina";
import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Container,
  Input,
  Row,
  Table,
} from "reactstrap";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
  useColumnOrder,
} from "react-table";

import { initialRows } from "Settings";
import { useActservper } from "../../features";
import "../../app.css";
import { GoDownload } from "react-icons/go";
import NumberFormatter from "features/components/NumberFormatter";
import * as XLSX from "xlsx-js-style";
import { saveAs } from "file-saver";

const Actividad = () => {
  const { data: dataActservper, isLoading, isError } = useActservper();
  const actividad = isLoading ? [] : dataActservper?.data;
  const [prudoctPerPage, setPrudoctPerPage] = useState(initialRows);
  const [currentPage, setCurrentPage] = useState(1);
  const lastIndex = currentPage * prudoctPerPage;
  const firstIndex = lastIndex - prudoctPerPage;

  const formatDate = (value) => {
    return new Date(value).toLocaleDateString();
  };

  const formatNumber = (number) => {
    return new Intl.NumberFormat("es-ES", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(number);
  };

  const columns = React.useMemo(
    () => [
      { Header: "ID", accessor: "acserid" },
      { Header: "Grupo", accessor: "groupClie.groupnomb" },
      { Header: "Periodo", accessor: "periodo.peridesc" },
      {
        Header: "Servicio",
        accessor: "serv.servdesc",
        Cell: ({ value }) => {
          return value.length > 40 ? value.substring(0, 60) + " ..." : value;
        },
      },
      { Header: "Recurso", accessor: "recurso.recunomb" },
      {
        Header: "Cantidad",
        accessor: "acsecant",
        Cell: ({ value }) => <NumberFormatter value={value} />,
      },
      { Header: "Unidad", accessor: "unidad.uniddeco" },
      { Header: "Concepto", accessor: "concor.coordesc" },
      {
        Header: "Modalidad",
        accessor: "acsemod",
        Cell: ({ value }) => {
          if (value === "N") {
            return "Nomina";
          } else {
            return "Nube";
          }
        },
      },
      {
        Header: "Observaciones",
        accessor: "acesobvs",
        Cell: ({ value }) => {
          return value.length > 40 ? value.substring(0, 60) + " ..." : value;
        },
      },
      {
        Header: "Fecha de Creación",
        accessor: "acseCreateAt",
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: "Fecha de Actualización",
        accessor: "acseUpdateAt",
        Cell: ({ value }) => formatDate(value),
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: actividad,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useColumnOrder
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    footerGroups,
    state,
    setGlobalFilter,
  } = tableInstance;
  const calculateTotalCantidad = useMemo(() => {
    return rows.reduce(
      (sum, row) => sum + (parseFloat(row.values.acsecant) || 0),
      0
    );
  }, [rows]);
  const { globalFilter } = state;

  useEffect(() => {
    setCurrentPage(1);
  }, [state.filters]);
  const generateExcel = () => {
    const headers = columns.map((column) => column.Header);
    const data = rows.map((row) =>
      columns.map((column) => row.values[column.accessor] || "")
    );

    const totalRow = columns.map((column) => {
      if (column.Header === "Cantidad") {
        return formatNumber(calculateTotalCantidad);
      }
      return "";
    });

    const wsData = [headers, ...data, totalRow];
    const ws = XLSX.utils.aoa_to_sheet(wsData);

    const headerStyle = {
      font: { bold: true },
      fill: { fgColor: { rgb: "FFFF00" } },
    };

    const totalStyle = {
      font: { bold: true },
      fill: { fgColor: { rgb: "FFA500" } },
    };

    ws["!cols"] = headers.map(() => ({ wch: 20 }));
    headers.forEach((_, colIndex) => {
      const cellRef = XLSX.utils.encode_cell({ r: 0, c: colIndex });
      ws[cellRef].s = headerStyle;
    });

    totalRow.forEach((_, colIndex) => {
      const cellRef = XLSX.utils.encode_cell({
        r: data.length + 1,
        c: colIndex,
      });
      ws[cellRef].s = totalStyle;
    });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Movimientos");

    const now = new Date();
    const fileName = `movimientos_totales_${now
      .toISOString()
      .replace(/[-T:.Z]/g, "_")}.xlsx`;

    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
      cellStyles: true,
    });

    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, fileName);
  };

  if (isLoading) return <IsLoading />;
  if (isError) return <IsError />;

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Input
                    placeholder="Search"
                    style={{ width: "20%" }}
                    value={globalFilter || ""}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                  />
                  <div className="col text-right">
                    <Button
                      title="Descargar reporte"
                      onClick={generateExcel}
                      style={{
                        backgroundColor: "transparent",
                        marginLeft: "10px",
                        boxShadow: "none",
                        color: "black",
                        transition: "background-color 0.3s ease",
                        ":hover": {
                          backgroundColor: "blue",
                          color: "white",
                        },
                      }}
                    >
                      <GoDownload />
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <div className="table-responsive-wrapper">
                <div className="table-responsive table-container">
                  <Table
                    {...getTableProps()}
                    className="align-items-center table-flush custom-table"
                  >
                    <thead className="thead-light">
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("Header")}
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? " ▼"
                                  : " ▲"
                                : ""}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.slice(firstIndex, lastIndex).map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => (
                              <td
                                {...cell.getCellProps()}
                                style={{
                                  borderRight: "1px solid #e9ecef",
                                  padding: "10px",
                                  margin: "0",
                                }}
                                className={
                                  cell.column.Header === "ID" ||
                                  cell.column.Header === "Estado" ||
                                  cell.column.Header === "Fecha de Creación" ||
                                  cell.column.Header ===
                                    "Fecha de Actualización"
                                    ? "text-center"
                                    : cell.column.Header === "Cantidad" ||
                                      cell.column.Header === "Costo"
                                    ? "text-right"
                                    : ""
                                }
                              >
                                {cell.render("Cell")}
                              </td>
                            ))}
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot style={{ backgroundColor: "#f6f9fc", padding: "0" }}>
                      {footerGroups.map((group) => (
                        <tr {...group.getFooterGroupProps()}>
                          {group.headers.map((column) => (
                            <td
                              style={{
                                borderRight: "1px solid #e9ecef",
                                paddingRight: "0px",
                                paddingBottom: "0px",
                              }}
                              {...column.getFooterProps()}
                            >
                              {column.Header === "Cantidad" ? (
                                <strong>
                                  Total: {formatNumber(calculateTotalCantidad)}
                                </strong>
                              ) : (
                                column.render("Footer")
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tfoot>
                  </Table>
                </div>
              </div>
              <CardFooter className="py-4">
                <div
                  className="col text-right"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Input
                    type="select"
                    style={{ width: "auto" }}
                    value={prudoctPerPage}
                    onChange={(e) => setPrudoctPerPage(Number(e.target.value))}
                  >
                    {[5, 7, 10, 25, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </Input>
                  <nav aria-label="...">
                    <Pagina
                      prudoctPerPage={prudoctPerPage}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      totalProduct={rows.length}
                    />
                  </nav>
                </div>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Actividad;
