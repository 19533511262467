import { instance } from "../../../api/axios";

export const fetchActservper = () => instance.get("/actservper");
export const fetchActservperById = (id) => instance.get(`/actservper/${id}`);
export const fetchActservperByServices = (id, idPeriodo) =>
  instance.get(`/actservper/services/${id}?idPeriodo=${idPeriodo}`);
export const createActservper = (data) => instance.post("/actservper", data);
export const updateActservper = (data) =>
  instance.put(`/actservper/${data.id}`, data.data);
export const deleteActservper = (id) => instance.delete(`/actservper/${id}`);
export const fetchConceptosById = (id) =>
  instance.get(`/concorde/services/${id}`);
export const fetchGroupClients = (id) =>
  instance.get(`/group-client/services/${id}`);
