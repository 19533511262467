import React from "react";

const NumberFormatter = ({
  value,
  currencySymbol = "",
  maximumFractionDigits = 10,
  locale = "en-US",
}) => {
  const formattedValue = new Intl.NumberFormat(locale, {
    maximumFractionDigits,
  }).format(value);

  return (
    <span>
      {currencySymbol && `${currencySymbol} - `}
      {formattedValue}
    </span>
  );
};

export default NumberFormatter;
