import React from "react";
import ReactDOM from "react-dom/client";
import { Route, Switch, Redirect, HashRouter } from "react-router-dom";
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import AdminLayout from "./layouts/Admin.js";
import AuthLayout from "./layouts/Auth.js";
import PrivateRoute from "./features/components/PrivateRoute";
import { QueryClient, QueryClientProvider } from "react-query";
import { extendTheme } from "@chakra-ui/react";
import { ReactQueryDevtools } from "react-query/devtools";
import { ChakraProvider } from "@chakra-ui/react";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();

const theme = extendTheme({
  fonts: {
    heading: "Poppins, sans-serif",
    body: "Poppins, sans-serif",
  },
  colors: {
    Obv: {
      100: "#000380",
    },
  },
});

root.render(
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools />
    <ChakraProvider theme={theme}>
      <HashRouter>
        <Switch>
          <PrivateRoute
            path="/admin"
            roles={[
              "superadmin",
              "developer",
              "administrator",
              "coordinator",
              "ViewMovimientos",
            ]}
            component={AdminLayout}
          />
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Redirect from="*" to="/admin/index" />
        </Switch>
      </HashRouter>
    </ChakraProvider>
  </QueryClientProvider>
);
